import React, { useState } from 'react';
import styled from 'styled-components';
import ImageClose from '../../assets/img/close.png';
import ImageCloseHighlight from '../../assets/img/closeHighlight.png';
import ImageSubmit from '../../assets/img/submit.png';
import ImageSubmitHighlight from '../../assets/img/submitHighlight.png';
import DatePicker from 'react-datepicker';
import { createGlobalStyle } from 'styled-components';

import "react-datepicker/dist/react-datepicker.css";

//styling for date and time popup calendar
const DatePickerStyles = createGlobalStyle`
  .react-datepicker {
    background-color: #222;
    border: 0px solid #0AC18E;
  }
  .react-datepicker__day {
    color: #fff;
  }
  .react-datepicker__day--selected {
    background-color: #0AC18E;
  }
  .react-datepicker__day:hover {
    color: #000;
  }
  .react-datepicker__header {
    background-color: #099971;
  }
  .react-datepicker__current-month {
    color: #fff;
  }
  .react-datepicker__day-name {
    color: #fff;
  }

  .react-datepicker-time__header {
    color: #fff;
  }
  .react-datepicker__time-box {
    background: #222;
  }
  .react-datepicker__time-list-item {
    color: #fff; 
  }
  .react-datepicker__time-list-item:hover {
    color: #000; 
  }
  .react-datepicker__time-list-item--selected {
    background-color: #099971 !important; /* Change to your preferred color for selected time */
  }
`;

interface ModalEntryProps {
  onSubmit: (eventData: any) => void;
  onClose: () => void;
  start?: Date
}

const ModalEntry: React.FC<ModalEntryProps> = ({ onSubmit, onClose, start }) => {
  const [title, setTitle] = useState('');
  const titleLength = 50;
  //const [eventTime, setEventTime] = useState('');

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date(startDate.getTime() + 60 * 60 * 1000)); // Adds one hour to startDate
  const [hour, setHour] = useState("");
  const [minute, setMinute] = useState("");

  const [description, setDescription] = useState('');
  const descriptionLength = 700;
  const [socials, setSocials] = useState('');
  const [image, setImage] = useState('');
  const [video, setVideo] = useState('');

  const handleSubmit = () => {
    //onSubmit({ title, description, start, hour, minute });
    onSubmit({ title, description, startDate, endDate });
    onClose();
  };

  const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
  let formattedDate;
  if (start) {
    formattedDate = start.toLocaleDateString('en-US', options);
  }
  
  return (
    <ModalContent onClick={(e) => e.stopPropagation()}>
      <DatePickerStyles />
      <StyledText>Enter Event details for:</StyledText>

      {start ? <StyledDate>{formattedDate}</StyledDate> : <></>}
      <StyledTimeDescription>Enter time using the events timezone. Calendar will convert it to UTC.</StyledTimeDescription>

      <StyledSystemTime>
        Your time: {startDate.toLocaleString('en-US', {
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          dateStyle: 'full',
          timeStyle: 'long'
        })}
      </StyledSystemTime>

      <StyledTime>
        <StyledDateTimePicker>
          <div>Start:</div>
          <DatePicker
            selected={startDate}
            onChange={(date: Date) => setStartDate(date)}
            showTimeSelect
            dateFormat="MMMM d, yyyy, h:mm aa"
          />
        </StyledDateTimePicker>
        
        <StyledDateTimePicker>
          <div>End:</div>
          <DatePicker
            selected={endDate}
            onChange={(date: Date) => setEndDate(date)}
            showTimeSelect
            dateFormat="MMMM d, yyyy, h:mm aa"
          />
        </StyledDateTimePicker>
      </StyledTime>

      <StyledTitle>
        <input placeholder="Title" value={title} onChange={(e) => setTitle(e.target.value)} />
      </StyledTitle>
      
      { title.length > titleLength &&
        <StyledTitleCounter>-{title.length - titleLength}</StyledTitleCounter>
      }
      
      <StyledDescription><textarea placeholder="Description. Innapropriate events will be deleted without refund." value={description} onChange={(e) => setDescription(e.target.value)} /></StyledDescription>
      
      { descriptionLength - description.length >= 0 ? (
        <StyledCharacterCounter>Characters left: {descriptionLength - description.length}</StyledCharacterCounter>   
      ) : (
        <StyledCharacterCounterRed>Characters left: -{description.length - descriptionLength}</StyledCharacterCounterRed>
      )}
        
     {/*
        <StyledSocials><input placeholder="Socials" value={socials} onChange={(e) => setSocials(e.target.value)} /></StyledSocials>
        <StyledLogo><input placeholder="Image" value={image} onChange={(e) => setImage(e.target.value)} /></StyledLogo>
        <StyledVideo><input placeholder="Video" value={video} onChange={(e) => setVideo(e.target.value)} /></StyledVideo>
    */}

        <SubmitButton onClick={handleSubmit}></SubmitButton>
        <CloseButton onClick={onClose} />
    </ModalContent>
  );
};

const ModalContent = styled.div`
  position: fixed;
  display: flex;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  height: 550px;
  background-color: black;
  display: flex;
  z-index: 10;
  border: 1px solid #0AC18E;
  flex-direction: column; /* Stack content vertically */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  color: green;
  border-radius: 40px 40px 0px 40px;
  padding: 1rem;    /* Add padding if needed to prevent content sticking to edges */
  @media (max-width: 700px) { /* You can adjust the breakpoint as needed */
    width: 90%;
    height: 75%;
    top: 57%;
  }
  input {
    width: 80%;
  }
`;
const StyledDateTimePicker = styled.div`
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  text-align: center;
  border: 0px solid red;
  .react-datepicker-wrapper {
    width: 100%; // Adjust width as needed
  }
`;

const StyledText = styled.div`
  position: relative;
  display: flex;
  color: #fff;
  justify-content: center;
  font-weight: 700;
  z-index: 5;
`;
const StyledTime = styled.div`
  position: relative;
  display: flex;
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  justify-content: center;
  z-index: 5;
`;
const StyledTimeDescription = styled.div`
  position: relative;
  display: flex;
  color: #fff;
  font-size: 14px;
  justify-content: center;
  z-index: 5;
`;
const StyledTitle = styled.div`
  position: relative;
  display: flex;
  top: 10px;
  justify-content: center;
  z-index: 4;
`;
const StyledDescription = styled.div`
  position: relative;
  top: 30px;
  height: 50%;
  width: 100%;
  display: flex;
  justify-content: center;
  border: 0px solid yellow;
  overflow-y: auto;
  overflow-x: hidden;
  textarea {
    font-size: 14px;
    width: 100%;
    resize: none;
    word-wrap: break-word;
    text-align: left;
    vertical-align: top;
  }
`;
const StyledCharacterCounter = styled.div`
  display: flex;
  color: #fff;
  font-size: 14px;
  position: relative;
  left: 0px;
  top: 30px;
  justify-content: right;
`;
const StyledCharacterCounterRed = styled.div`
  display: flex;
  color: red;
  font-size: 14px;
  position: relative;
  left: 0px;
  top: 30px;
  justify-content: right;
`;
const StyledTitleCounter = styled.div`
  display: flex;
  color: red;
  font-size: 20px;
  font-weight: 700;
  position: absolute;
  right: 26px;
  top: 62px;
  justify-content: right;
`;
const StyledDate = styled.div`
  display: flex;
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  position: relative;
  top: 0px;
  justify-content: center;
`;
const CloseButton = styled.button`
  background-image: url(${ImageClose});
  background-size: cover;
  position: absolute;
  top: -8px;
  right: -8px;
  background-color: transparent;
  width: 75px;
  height: 45px;
  border: 1px solid #0AC18E;
  border-radius: 0px 0px 0px 0px;
  cursor: pointer;
  display: flex;
  z-index: 11;
  &:hover {
    background-image: url(${ImageCloseHighlight});
  }
`;
const SubmitButton = styled.button`
  background-image: url(${ImageSubmit});
  background-size: cover;
  position: absolute;
  bottom: 10px;
  left: 40%;
  background-color: transparent;
  width: 100px;
  height: 48px;
  border: 0px solid #0AC18E;
  border-radius: 0px 0px 0px 0px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  &:hover {
    background-image: url(${ImageSubmitHighlight});
  }
`;
const StyledSystemTime = styled.div`
  position: relative;
  display: flex;
  color: #aaa;
  font-size: 14px;
  justify-content: center;
  margin: 5px 0;
  z-index: 5;
`;
export default ModalEntry;
