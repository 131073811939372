import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Header from '../../components/Header';
import QRcode from '../../components/QRcode';
import { Calendar, momentLocalizer } from 'react-big-calendar';
//import EventHighlights from '../../components/EventHighlights';
import moment from 'moment';
import ModalCalendar from '../../components/ModalCalendar'; //event more data 
import ModalEntry from '../../components/ModalEntry';    //event submission modal
import 'react-big-calendar/lib/css/react-big-calendar.css';
import ImageBackground from '../../assets/img/bchBackground.png';
import BigPic from '../../assets/img/casualLogo.png'

const localizer = momentLocalizer(moment);

interface Event {
  title: string;
  description: string;
  start: string;
  hour: string;
  minute: string;
  end: string;
  id: string;
  address: string;
  timestamp: number;
}

const Events = () => {
  // Define the type for events explicitly
  //const [events, setEvents] = useState<{ start: Date; end: Date; title: string; id: string }[]>([]);
  const [events, setEvents] = useState<Event[]>([]);
  const [selectedEvent, setSelectedEvent] = useState<any>(null);
  const [paymentAddress, setPaymentAddress] = useState<string>('');
  const [uuid, setUuid] = useState<string>('');
  const [showEntryModal, setEntryModal] = useState(false);
  const [startDate, setStartDate] = useState<Date>();

//////////////////////////////////////////  
/////  Get calendar data from server when this component loads
//////////////////////////////////////////
  useEffect(() => {
    const checkServerHealth = async () => {
      try {
        const response = await fetch('https://helpme.cash/api/health');
        if (response.ok) {
          fetchEvents();
        } else {
          console.log("Server is not healthy.");
        }
      } catch (error) {
        console.log("Server is down:", error);
      }
    };

    const fetchEvents = async () => {
      const response = await fetch('https://helpme.cash/api/events');
      const text = await response.text();
      //console.log("Server response:", text);
      const data: Event[] = JSON.parse(text);

      const desanitizedData = data.map(event => ({
        ...event,
        title: unescapeHtml(event.title),
        description: unescapeHtml(event.description)
      }));

      setEvents(desanitizedData);
    };

    checkServerHealth();
  }, []); // the [] means it will only run once on initial load. e.g. [events] would re-run it every time the events variable changed

//////////////////////////////////////////  
/////  De-sanitize text field
//////////////////////////////////////////
  function unescapeHtml(safe: any) {
    return safe
      .replace(/&amp;/g, '&')
      .replace(/&#x2F;/g, '/')
      .replace(/&lt;/g, '<')
      .replace(/&gt;/g, '>')
      .replace(/&quot;/g, '"')
      .replace(/&#039;/g, "'")
      .replace(/&#x27;/g, "'");
  }

//////////////////////////////////////////  
/////  Track what event is clicked
//////////////////////////////////////////
  const handleSelectEvent = (event: any) => {
    if (!showEntryModal && paymentAddress == '') {
     setSelectedEvent(event);
    }
  };

//////////////////////////////////////////  
/////  Open data entry when day is clicked
//////////////////////////////////////////
  const handleSelect = async ({ start, end }: { start: Date; end: Date }) => {
    if (!showEntryModal && selectedEvent == null && paymentAddress == '') {
      setStartDate(start);
      setEntryModal(true);
    }
  }
//////////////////////////////////////////  
/////  Handle submitting a new event
//////////////////////////////////////////
  const handleModalSubmit = async (eventData: any) => {
    /*
    //console.log('before startDate: ');
    //console.log(eventData.start);

    //create endDate based off startDate
    const endDate = new Date(eventData.start);
    endDate.setDate(eventData.start.getDate() + 1);
    eventData.start.setHours(eventData.hour, eventData.minute);
    //console.log('after startDate: ');
    //console.log(eventData.start);

    //get startDate and update it with users hour and minute selection
    const startDate = new Date(eventData.start);
    startDate.setHours(eventData.hour);
    startDate.setMinutes(eventData.minute);

    const newEvent = { ...eventData, end: endDate }; // Replace with your actual start and end dates
*/
      // Create a new object with the event data, including the start and end dates
  const newEvent = {
    title: eventData.title,
    description: eventData.description,
    start: eventData.startDate.toISOString(), // Convert to ISO string
    end: eventData.endDate.toISOString() // Convert to ISO string
  };
    const response = await fetch('https://helpme.cash/api/events', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(newEvent),
    });

    if (response.ok) {
      const data = await response.json();
      setPaymentAddress(data.address);
      setUuid(data.id);
      console.log('Pay BCH to: ' + data.address + ', UUID: ' + data.id);
      setEntryModal(false);
    }
  };
//////////////////////////////////////////  
/////  Close Event modal
//////////////////////////////////////////
  const handleCloseModal = () => {
    setSelectedEvent(null);
  };

//////////////////////////////////////////  
/////  Close Event modal
//////////////////////////////////////////
const handleClearQR = () => {
  setPaymentAddress('');
  setUuid('');
};
//////////////////////////////////////////  
/////  Delete an ID from the local events variable
//////////////////////////////////////////
  const handleDeleteById = async (id: string) => {
      setEvents(events.filter(e => e.id !== id));
      setSelectedEvent(null);
    }

  return (
    <StyledEvents>
      <BackgroundDiv />
      <StyledTitle>BCH Ecosystem Events</StyledTitle>
      <StyledText>All times in UTC. 0.0001 BCH to enter an event.</StyledText>
  
        <StyledCalendar>
          <Calendar
            localizer={localizer}
            selectable
            events={events}
            defaultView="month"
            views={['month', 'agenda']}
            defaultDate={new Date()}
            onSelectSlot={handleSelect}
            onSelectEvent={handleSelectEvent}
          />
        </StyledCalendar>

        {selectedEvent && (
          <ModalCalendar onClose={handleCloseModal} event={selectedEvent} onDelete={handleDeleteById}/>
        )}

      {showEntryModal && (
        <ModalEntry
          onSubmit={handleModalSubmit}
          onClose={() => setEntryModal(false)}
          start={startDate}
        />
      )}

      {paymentAddress !== '' && (
        <StyledQR>
          <StyledAddress>Entries can take up to 1 minute to appear.</StyledAddress>
          <StyledAddress>(No feedback on successful payment yet.)</StyledAddress>
          Pay 0.0001 BCH
          <QRcode address={paymentAddress} clearQR={handleClearQR} satoshis={BigInt(10000)}/>
          <StyledAddress>{paymentAddress}</StyledAddress><br />
          <StyledAddress>ID: {uuid}</StyledAddress>
        </StyledQR>
      )}

    </StyledEvents>
  );
};

const StyledCalendar = styled.div`
  background-color: #000;
  opacity: 0.8;
  width: 90%;
  margin-left: 5%;
  height: 800px;
  color: #fff;
  border: 2px solid #0AC18E;
  padding: 0px;
  .rbc-header {
    border-bottom: 1px solid #0AC18E;
  }
  .rbc-show-more {
    color: #0AC18E;
    background-color: #fff;
  }
  .rbc-agenda-event-cell {
    &:hover {
      background-color: #333;
      font-weight: 700;
      border: 1px solid #0AC18E;
    }
  }
  .rbc-toolbar button {
    color: #fff;
    border: 1px solid #0AC18E;
  }
  .rbc-toolbar button:active, .rbc-toolbar button.rbc-active {
    background-color: #0AC18E;
    border-color: #adadad;
  }
  .rbc-toolbar button:active:hover, .rbc-toolbar button:active:focus, .rbc-toolbar button.rbc-active:hover, .rbc-toolbar button.rbc-active:focus {
    background-color: #004295;
    color: #fff;
  }
  .rbc-toolbar button:focus {
    background-color: #fff;
  }
  .rbc-toolbar button:hover {
    background-color: #004295;
  }
  .rbc-event, .rbc-day-slot .rbc-background-event {
    background-color: #0AC18E;
    display: flex;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    width: 95%;
    margin-left: 2%;
    text-align: left;
    font-size: 16px;
    &:hover {
      border: 1px solid #fff;
      font-weight: 600;
    }
    @media (max-width: 832px) {
      font-size: 12px;
    }
  }
  .rbc-month-view {
    border: 1px solid #0AC18E;
  }
  .rbc-day-bg + .rbc-day-bg {
    border-left: 1px solid #fff;
    &:hover {
      background-color: #2b2b2b;
    }
  }
  .rbc-off-range-bg {
    background: #333;
    opacity: 0.7;
  }
  .rbc-today {
    background-color: #004295;
  }
  .rbc-month-row {
    border-top: 1px solid #fff;
  }
  .rbc-header + .rbc-header {
    border-left: 1px solid #0AC18E;
  }
`;
const BackgroundDiv = styled.div`
  background-image: url(${BigPic});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 0px;
  display: flex;
  height: 100%;
  width: 100%;
  border: 0px solid red;
  z-index: -1;
  opacity: 0.4;
`;
const StyledEvents = styled.div`
  height: 800px;
  width: 100%;
  color: white;
  border: 0px solid green;
  margin-right: 50px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  font-size: 20px;
  padding-bottom: 30px;
`;
const StyledTitle = styled.div`
  background-color: #000;
  opacity: 0.6;
  border: 0px solid red;
  font-size: 40px;
  padding: 1px;
  color: #fff;
  display: flex;
  position: relative;
  justify-content: center;
`;
const StyledQR = styled.div`
  background: transparent;
  background-size: cover;
  position: absolute;
  left: 35%;
  width: 450px;
  height: 400px;
  background-color: #fff;
  border: 4px solid #0AC18E;
  border-radius: 30px 30px 30px 30px;
  font-size: 30px;
  font-weight: 700;
  padding: 1px;
  color: #000;  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10;
`;
const StyledAddress = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: #000;  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const StyledText = styled.div`
  position: relative;
  display: flex;
  color: fff;
  justify-content: center;
  z-index: 5;
  background-color: #000;
  opacity: 0.6;
`;

export default Events;